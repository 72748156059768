.yvebot-form {
    display: none;
}

.yvebot-conversation {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
    width: 400px;
    max-height: 400px;
    padding: 20px;
}

.yvebot-thread {
    width: 60%;
    padding: 12px;
    border-radius: 15px;
    line-height: 20px;
    box-shadow: black 1px 1px 5px;
}

.yvebot-thread-bot {
    align-self: flex-start;
    background-color: rgb(58, 135, 223);
    color: white;
}

.yvebot-thread-user {
    align-self: flex-end;
    background-color: white;
    color: black;
}

.yvebot-message-bubbles {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 6% auto;
}

.yvebot-thread.yvebot-thread-bot.yvebot-thread-typing {
    display: none;
}


.yvebot-message-bubbleBtn {
  appearance: none;
  background-color: #3fc246;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.yvebot-message-bubbleBtn:hover {
  background-color: #2c974b;
}

@media screen and (max-width: 700px) {
  .yvebot-conversation {
    width: 90%;
  }
}
