.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    align-self: center;
    margin: auto;
}

.card:hover{
  border-color: aqua;
}

.column {
  align-self: center;
    margin: auto;
    padding: 4px;
}

.column > .card img {
    object-fit: contain;
}

.text {
  color: #000;
  text-align: center;
  padding: 5px 0;
  font-size: 1.5vw;
}

.details {
  text-align: center;
  color: #000;
  font-size: 1.2vw;
  padding: 5px 0;
  line-height: 20px;
}

.image {
  width: 20vw;
}


@media screen and (max-width: 850px) {
    .text {
      font-size: 3vw;
    }

    .details {
      font-size: 2.8vw;
    }

    .image {
      width: 40vw;
    }
}

@media screen and (max-width: 500px) {
  .column {
    width: 100%;
    display: block;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* margin-left: 40px;
    margin-top: 30px; */
  }

  .image {
    width: 90vw;
  }
}
