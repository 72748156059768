.footerContainer {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
    background: #000;
    line-height: 25px;
    padding-top: 25px;
    box-shadow: 0 0 10px #000;
}

/* .mapContainer {
    width: 70vw;
    height: auto;
    padding-bottom: 2rem;
} */

.footRow {
    display: flex;
    width: 33.33%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
}

.row {
    margin-bottom: 20px;
}

.footerContainer h4 {
  width: 25vw;
  font-size: 1.5vw;
  color: #fff;
  border-bottom: 2px solid #3db166;
  padding-bottom: 0.5vw;
  text-align: center;
  margin-bottom: 1vw;
}

.footerContainer ul, li {
    padding-left: 0.5rem;
    margin: 2vw 0;
    font-size: 1.25vw;
    box-sizing: border-box;
}

ul:not(.browser-default)>li {
    list-style-type: none;
}

.location {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-size: 1.25vw;
  color: #fff;
}

@media screen and (max-width: 750px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footerContainer h4 {
    width: 40vw;
    font-size: 5vw;
  }

  .location {
    font-size: 2.5vw;
  }

  .row {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .location {
    font-size: 3vw;
  }
}
