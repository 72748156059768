.chatbot {
    border: 3px solid #000;
    background-color: #fff;
}

.bottomRight {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
}

.topBar {
  border-bottom: 3px solid #000;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 2px 0;
}

.close {
  border: none;
  background-color: #fff;
}

.ask {
  display: flex;
  border: none;
  justify-content: flex-end;
  background-color: transparent;
  transition: 0.5s;
  margin: 10px;
  right: 0;
}

.ask:hover {
  transform: scale(1.2);
}

.ask:active {
  transform: scale(0.8);
}

.invisible {
  display: none;
}

@media screen and (max-size: 700px) {
  .chatbot {
    width: 1vw;
  }
}
