.representatives {
    display: flex;
    flex-flow: column;
    gap: 3vw;
    margin: 80px 50px;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .representatives {
      gap: 10vw;
    }
  }
