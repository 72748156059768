.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
    cursor: default;
}

.link {
  color: #f00;
  text-align: center;
  font-size: 1.5vw;
  margin: auto;
}

.modal .warden:hover {
  color: #6a6;
}

.card, .modal .link {
    background-color: #fff;
    padding: 10px;
    /* width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center; */
}

.card {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 3;
    max-height: 80vh;
    overflow-y: scroll;
}

.link:hover {
    color: blue;
}

.image {
  width: 40vw;
}

.frame {
    border: 0;
    width: 40vw;
    height: 40vh;
}

.text {
  color: #000;
  text-align: center;
  padding: 5px 0;
  font-size: 1.5vw;
}

.details {
  text-align: center;
  color: #000;
  font-size: 1.2vw;
  padding: 5px 0;
  line-height: 30px;
}

.linkWrap{
  margin: auto;
  width: fit-content;
}

@media screen and (max-width: 700px) {
    .text {
      font-size: 3vw;
    }

    .details {
      font-size: 2.8vw;
    }

    .modal .link {
      font-size: 3vw;
    }

    .image {
      width: 80vw;
    }

    .frame {
        width: 80vw;
    }
}
