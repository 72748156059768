.cardOutline {
    box-sizing: border-box;
    border: 3px solid #000;
    box-shadow: grey 3px 3px;
    width: min(70vw, 1200px);
    height: min(20vw, 290px);
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1vw;
    background: #fff;
    align-items: center;
    border-radius: 20px;
    padding: 2% 5%;
}

.personInfo {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 100%;
}

.name {
    font-size: min(2.5vw, 40px);
    color: #000;
}

.designation {
    font-size: min(2vw, 34px);
}

.contact {
    font-size: min(1.3vw, 22px);
}

.button {
    transition: background-color 0.5s ease-in-out;
    background-color: #000;
    outline: none;
    border: none;
    width: 120px;
    height: 2em;
    font-size: min(3.5vw, 20px);
}

.button:hover {
    transition: background-color 0.5s ease-in-out;
    background-color: #555;
}

.image {
    height: min(100%, 15vw);
}

.cardOutline > a {
    margin-left: auto;
    margin-right: 5px;
}

@media screen and (max-width: 750px) {
    .image {
        width: 50%;
        height: auto;
    }

    .cardOutline {
        width: 80vw;
        padding: 5vw;
        flex-direction: column;
        height: fit-content;
        gap: 2vw;
    }

    .name {
        font-size: 6vw;
    }

    .designation {
        font-size: 5vw;
    }

    .contact {
        font-size: 3.5vw;
    }

    .cardOutline > a {
        margin: auto;
    }

    /* .cardOutline {
  height:
  } */
}

@media screen and (max-width: 640px) {
    .cardOutline {
        flex-direction: column;
    }

    .button {
        width: 100%;
    }
}

/* @media screen and (max-width: 1000px) {
    .cardOutline {
        width: 50vw;
        padding: 5vh;
        flex-flow: column;
        height: 70vh;
        gap: 2vh;
    }

    .image {
        height: 50%;
    }

    .button {
        width: 40vw;
    }
} */
