.box {
    box-shadow: 0 0 20px #000;
    background-image: url("../../Images/Common/webp/bg.webp");
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.box p {
  font-size: min(6vw, 60px);
}

@media screen and (max-width: 700px) {
  .box p {
    font-size: 6vw;
  }
}
