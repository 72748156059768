.cardRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2vw 5vw 2vw 5vw;
    gap: 2vw;
}

.link:hover {
    color: #000;
}

.container {
    width: 40vw;
}

.frame {
  width: 90vw;
}

.ulTypeA {
    list-style-type: circle !important;
    list-style-position: inside;
}

@media screen and (max-width: 900px) {
    .cardRow {
        grid-template-columns: 1fr 1fr;
        padding: 2vw 6vw;
        gap: 3vw;
    }

    .container {
        width: 90vw;
    }
}


@media screen and (max-width: 500px) {
    .cardRow {
        grid-template-columns: 1fr;
        gap: 4vw;
    }
}
