.root-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    align-items: center;
    gap: 4vh;
}

body {
    background-color: #f2f2f2;
    color: #666;
}

a {
    text-decoration: none;
    color: #88d;
}

a:hover {
    color: white;
}

li {
    color: #000;
    list-style-type: circle;
}

.wrapper {
    padding: 6rem 0 4rem 0;
    position: relative;
    margin: 0;
}

.wrapper.style3 {
    background-color: #000;
    color: #bfbfbf;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;
}

.wrapper.style3 input,
.wrapper.style3 select,
.wrapper.style3 textarea {
    color: #ffffff;
}

.wrapper.style3 a {
    color: #8a4680;
}

.wrapper.style3 strong,
.wrapper.style3 b {
    color: #ffffff;
}

.wrapper.style3 h1,
.wrapper.style3 h2,
.wrapper.style3 h3,
.wrapper.style3 h4,
.wrapper.style3 h5,
.wrapper.style3 h6 {
    color: #ffffff;
}

.wrapper.style3 blockquote {
    border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 hr {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .box {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="submit"],
.wrapper.style3 input[type="reset"],
.wrapper.style3 input[type="button"],
.wrapper.style3 button,
.wrapper.style3 .button {
    background-color: #f2f2f2;
    color: #000 !important;
}

.wrapper.style3 input[type="submit"]:hover,
.wrapper.style3 input[type="reset"]:hover,
.wrapper.style3 input[type="button"]:hover,
.wrapper.style3 button:hover,
.wrapper.style3 .button:hover {
    background-color: white;
}

.wrapper.style3 input[type="submit"]:active,
.wrapper.style3 input[type="reset"]:active,
.wrapper.style3 input[type="button"]:active,
.wrapper.style3 button:active,
.wrapper.style3 .button:active {
    background-color: #e5e5e5;
}

.wrapper.style3 input[type="submit"].alt,
.wrapper.style3 input[type="reset"].alt,
.wrapper.style3 input[type="button"].alt,
.wrapper.style3 button.alt,
.wrapper.style3 .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
    color: #ffffff !important;
}

.wrapper.style3 input[type="submit"].alt:hover,
.wrapper.style3 input[type="reset"].alt:hover,
.wrapper.style3 input[type="button"].alt:hover,
.wrapper.style3 button.alt:hover,
.wrapper.style3 .button.alt:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 input[type="submit"].alt:active,
.wrapper.style3 input[type="reset"].alt:active,
.wrapper.style3 input[type="button"].alt:active,
.wrapper.style3 button.alt:active,
.wrapper.style3 .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style3 input[type="submit"].alt.icon:before,
.wrapper.style3 input[type="reset"].alt.icon:before,
.wrapper.style3 input[type="button"].alt.icon:before,
.wrapper.style3 button.alt.icon:before,
.wrapper.style3 .button.alt.icon:before {
    color: #999999;
}

.wrapper.style3 input[type="submit"].special,
.wrapper.style3 input[type="reset"].special,
.wrapper.style3 input[type="button"].special,
.wrapper.style3 button.special,
.wrapper.style3 .button.special {
    background-color: #8a4680;
    color: #ffffff !important;
}

.wrapper.style3 input[type="submit"].special:hover,
.wrapper.style3 input[type="reset"].special:hover,
.wrapper.style3 input[type="button"].special:hover,
.wrapper.style3 button.special:hover,
.wrapper.style3 .button.special:hover {
    background-color: #9b4f90;
}

.wrapper.style3 input[type="submit"].special:active,
.wrapper.style3 input[type="reset"].special:active,
.wrapper.style3 input[type="button"].special:active,
.wrapper.style3 button.special:active,
.wrapper.style3 .button.special:active {
    background-color: #793d70;
}

.wrapper.style3 label {
    color: #ffffff;
}

.wrapper.style3 input[type="text"],
.wrapper.style3 input[type="password"],
.wrapper.style3 input[type="email"],
.wrapper.style3 select,
.wrapper.style3 textarea {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="text"]:focus,
.wrapper.style3 input[type="password"]:focus,
.wrapper.style3 input[type="email"]:focus,
.wrapper.style3 select:focus,
.wrapper.style3 textarea:focus {
    border-color: #8a4680;
    box-shadow: 0 0 0 1px #8a4680;
}

.wrapper.style3 .select-wrapper:before {
    color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"] + label,
.wrapper.style3 input[type="radio"] + label {
    color: #bfbfbf;
}

.wrapper.style3 input[type="checkbox"] + label:before,
.wrapper.style3 input[type="radio"] + label:before {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"]:checked + label:before,
.wrapper.style3 input[type="radio"]:checked + label:before {
    background-color: #8a4680;
    border-color: #8a4680;
    color: #ffffff;
}

.wrapper.style3 input[type="checkbox"]:focus + label:before,
.wrapper.style3 input[type="radio"]:focus + label:before {
    border-color: #8a4680;
    box-shadow: 0 0 0 1px #8a4680;
}

.wrapper.style3 ::-webkit-input-placeholder {
    color: #999999 !important;
}

.wrapper.style3 :-moz-placeholder {
    color: #999999 !important;
}

.wrapper.style3 ::-moz-placeholder {
    color: #999999 !important;
}

.wrapper.style3 :-ms-input-placeholder {
    color: #999999 !important;
}

.wrapper.style3 .formerize-placeholder {
    color: #999999 !important;
}

.wrapper.style3 li {
    border-top-color: rgba(255, 255, 255, 0.25);
    list-style-type: circle;
}

.wrapper.style3 header p {
    color: #999999;
}

.wrapper.style3 table tbody tr {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 table th {
    color: #ffffff;
}

.wrapper.style3 table thead {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tfoot {
    border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table.alt tbody tr td {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .inner {
    position: relative;
    z-index: 2;
}

.wrapper.style3 p {
    text-transform: uppercase;
    font-weight: 300;
    margin: 0 0 0.5rem 0;
    padding: 0 0 1rem 0;
    letter-spacing: 0.25rem;
}

.wrapper.style3 p:after {
    content: "";
    position: absolute;
    margin: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
}

.wrapper.style3:before {
    transition: opacity 3s ease;
    -moz-transition-delay: 1.25s;
    -webkit-transition-delay: 1.25s;
    -ms-transition-delay: 1.25s;
    transition-delay: 1.25s;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
