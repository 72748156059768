.introInfo {
    margin: 4vh;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2vw;
    grid-column-gap: 5vw;
}

.box > a {
  align-self: center;
}

@media screen and (max-width: 850px) {
    .box {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .box {
        display: flex;
        flex-direction: column;
        gap: 6vw;
    }
}
