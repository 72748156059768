.box {
    background: #FFF;
    box-sizing: border-box;
    padding: 1.5vw;
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 28vw;
    box-shadow: 2px 2px 5px 2px black;
}

.title {
    color: #000;
    margin: 2vw 0;
    font-size: min(4vw, 40px);
}

.content {
    line-height: min(2vw, 25px);
    font-size: min(1.5vw, 20px);
    text-align: justify;
}

.content a:hover {
    color: black;
}


@media screen and (max-width: 900px) {
    .title {
        font-size: 3vw;
    }

    .content {
        font-size: 1.7vw;
    }

    .box {
        width: 40vw;
        padding: 2vw;
    }
}

@media screen and (max-width: 500px) {
    .title {
        font-size: 6vw;
    }

    .content {
        font-size: 3vw;
        line-height: 15px;
    }

    .box {
        width: 80vw;
        padding: 4vw;
    }
}

/* @media screen and (max-width: 1000px) {
  .content {
    font-size: 5vw;
  }
} */
