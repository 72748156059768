.header {
    background: rgba(0, 0, 0, 0.975);
    color: #a6a6a6;
    cursor: default;
    height: fit-content;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-family: sans-serif;
    margin-bottom: 30px;
    z-index: 1000;
}

.navbar {
    width: 100%;
    background-color: black !important;
}

.container {
    margin-left: 15px;
}

.brand {
    display: flex;
    align-items: center;
    gap: 7%;
}

.brandImage {
    width: 40px;
    height: 40px;
}

.navbarCollapse {
    font-size: 1.3rem;
    gap: min(2%, 10px);
    flex-grow: 1;
}

.vr {
    margin: 1px 10px;
    color: white;
    opacity: 0.5;
}

@media screen and (max-width: 767px) {
    .vr {
        display: none;
    }
}
