.cardContainer {
    display: flex;
    box-sizing: border-box;
    padding: 50px;
    border: none;
    background-color: #fff;
    gap: 5%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 2px black;
    width: 80vw;
    max-width: 1500px;
}

.info {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 3vw;
    font-size: min(1.5vw, 20px);
    line-height: min(2vw, 30px);
}

.heading {
  color: #000;
  font-size: min(3vw, 50px);
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  gap: 120px;
  align-items: center;
  width: 40%;
}

.image {
    height: auto;
    width: 100%;
    max-width: 400px;
}

.footer {
  color: #000;
}

@media screen and (max-width: 750px) {
  .cardContainer {
    flex-direction: column;
    width: 90vw;
    padding: 7%;
  }

  .info {
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .heading {
    font-size: 6vw;
    width: 100%;
    align-items: center;
    gap: 30px;
    margin: auto auto 7% auto;
  }

  .image {
    width: 50vw
  }
}
