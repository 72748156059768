.para li, .para p {
  font-size: 1vw;
  line-height: 1.5vw;
}

.row {
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    margin: 50px auto;
}

.row a:hover {
  color: #000;
}

@media screen and (max-width: 700px) {

  .para li, .para p {
    font-size: 3vw;
    line-height: 4vw;
  }
}
