.wrapper {
    position: relative;
    background-image: url("../../Images/Common/webp/bg.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner p {
    position: relative;
    font-size: min(6vw, 60px);
    text-align: center;
    color: #fff;
    width: 100%;
    padding: 0 0 1.5rem 0;
}

.inner p::after {
    content: "";
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 700px) {
    .box p {
        font-size: 6vw;
    }
}

.slideContainer {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.slideImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.arrow{
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%,-50%);
}

@media screen and (max-width: 300px) {
    .arrow {
        transform: scale(0.5);
    }
}

.arrowSpan{
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #06A8FF;
    border-right: 5px solid #06A8FF;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrowSpan:nth-child(2){
    animation-delay: -0.2s;
}
.arrowSpan:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}