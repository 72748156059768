.gallery {
  margin: 20px 0;
  width: 60%;
  height: auto;
}

@media screen and (max-width: 700px) {
  .gallery {
    width: 90%;
  }
}
