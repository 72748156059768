.representatives {
    display: flex;
    flex-flow: column;
    gap: 3vw;
    margin: 80px 50px;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
}

.end {
  font-size: min(2vw, 25px);
}

.card {
    background-color: white;
    width: min(60vw, 1000px);
    border: 1px solid black;
    box-shadow: black 1px 1px 4px 1px;
    font-size: min(3vw, 30px);
    padding: 30px;
    color: black;

    border-radius: 15px 15px;
}

.card > p {
    text-align: center;
    font-weight: 700;
}

.list {
    margin: 30px 10px;
    display: block;
}

.list > li {
    list-style-type: square !important;
    font-size: min(2vw, 20px);
    margin: 20px 10px;
}

.list > li > p:nth-child(1) {
    font-size: min(2.5vw, 25px);
    font-weight: 600;
}

@media screen and (max-width: 700px) {
    .representatives {
      gap: 10vw;
    }

    .end {
      font-size: 5vw;
    }
  }
