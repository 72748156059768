.para {
    background: #fff;
    align-self: center;
    width: 95%;
    padding: 30px;
    border-radius: 10px;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 30px;
    gap: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.para li, .para p {
  font-size: 1vw;
  line-height: 2.5vw;
}

.para p strong {
  font-size: 2vw;
}

@media screen and (max-width: 1000px) {
  .para {
    padding: 50px 20px;
  }

  .para li, .para p {
    font-size: 3vw;
    line-height: 5vw;
  }

  .para p strong {
    font-size: 5vw;
  }
}

@media screen and (max-width: 1300px) {
  .para {
    padding: 50px 20px;
  }

  .para li, .para p {
    font-size: 2vw;
    line-height: 4vw;
  }

  .para p strong {
    font-size: 4vw;
  }
}
