.Header_header__19NaD {
    background: rgba(0, 0, 0, 0.975);
    color: #a6a6a6;
    cursor: default;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-family: sans-serif;
    margin-bottom: 30px;
    z-index: 1000;
}

.Header_navbar__2N5-0 {
    width: 100%;
    background-color: black !important;
}

.Header_container__2je19 {
    margin-left: 15px;
}

.Header_brand__3q1pk {
    display: flex;
    align-items: center;
    grid-gap: 7%;
    gap: 7%;
}

.Header_brandImage__224AJ {
    width: 40px;
    height: 40px;
}

.Header_navbarCollapse__2jLXM {
    font-size: 1.3rem;
    grid-gap: min(2%, 10px);
    gap: min(2%, 10px);
    flex-grow: 1;
}

.Header_vr__1K_-1 {
    margin: 1px 10px;
    color: white;
    opacity: 0.5;
}

@media screen and (max-width: 767px) {
    .Header_vr__1K_-1 {
        display: none;
    }
}

.Footer_footerContainer__1iz5G {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
    background: #000;
    line-height: 25px;
    padding-top: 25px;
    box-shadow: 0 0 10px #000;
}

/* .mapContainer {
    width: 70vw;
    height: auto;
    padding-bottom: 2rem;
} */

.Footer_footRow__EO9Kv {
    display: flex;
    width: 33.33%;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
}

.Footer_row__ll7LR {
    margin-bottom: 20px;
}

.Footer_footerContainer__1iz5G h4 {
  width: 25vw;
  font-size: 1.5vw;
  color: #fff;
  border-bottom: 2px solid #3db166;
  padding-bottom: 0.5vw;
  text-align: center;
  margin-bottom: 1vw;
}

.Footer_footerContainer__1iz5G ul, li {
    padding-left: 0.5rem;
    margin: 2vw 0;
    font-size: 1.25vw;
    box-sizing: border-box;
}

ul:not(.Footer_browser-default__2mphk)>li {
    list-style-type: none;
}

.Footer_location__3G0LI {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-size: 1.25vw;
  color: #fff;
}

@media screen and (max-width: 750px) {
  .Footer_footerContainer__1iz5G {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .Footer_footerContainer__1iz5G h4 {
    width: 40vw;
    font-size: 5vw;
  }

  .Footer_location__3G0LI {
    font-size: 2.5vw;
  }

  .Footer_row__ll7LR {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .Footer_location__3G0LI {
    font-size: 3vw;
  }
}

.About_para__2wptQ li, .About_para__2wptQ p {
  font-size: 1vw;
  line-height: 1.5vw;
}

.About_row__1sOHS {
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    grid-gap: 5vh;
    gap: 5vh;
    margin: 50px auto;
}

.About_row__1sOHS a:hover {
  color: #000;
}

@media screen and (max-width: 700px) {

  .About_para__2wptQ li, .About_para__2wptQ p {
    font-size: 3vw;
    line-height: 4vw;
  }
}

.AboutCard_cardContainer__2YZPl {
    display: flex;
    box-sizing: border-box;
    padding: 50px;
    border: none;
    background-color: #fff;
    grid-gap: 5%;
    gap: 5%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 2px black;
    width: 80vw;
    max-width: 1500px;
}

.AboutCard_info__1TCUu {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    grid-gap: 3vw;
    gap: 3vw;
    font-size: min(1.5vw, 20px);
    line-height: min(2vw, 30px);
}

.AboutCard_heading__JkZne {
  color: #000;
  font-size: min(3vw, 50px);
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  grid-gap: 120px;
  gap: 120px;
  align-items: center;
  width: 40%;
}

.AboutCard_image__2abL8 {
    height: auto;
    width: 100%;
    max-width: 400px;
}

.AboutCard_footer__5gpIA {
  color: #000;
}

@media screen and (max-width: 750px) {
  .AboutCard_cardContainer__2YZPl {
    flex-direction: column;
    width: 90vw;
    padding: 7%;
  }

  .AboutCard_info__1TCUu {
    font-size: 3vw;
    line-height: 3.5vw;
  }

  .AboutCard_heading__JkZne {
    font-size: 6vw;
    width: 100%;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    margin: auto auto 7% auto;
  }

  .AboutCard_image__2abL8 {
    width: 50vw
  }
}

.Card_box__3dzPP {
    background: #FFF;
    box-sizing: border-box;
    padding: 1vw 5vw 2.5vw 5vw;
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 80vw;
    max-width: 1500px;
    box-shadow: 2px 2px 5px 2px black;
}

.Card_title__1R24K {
    color: #000;
    margin: 2vw 0;
    font-size: min(4vw, 40px);
}

.Card_content__3mSSQ {
    line-height: min(2vw, 25px);
    font-size: min(1.5vw, 20px);
    text-align: justify;
}

.Card_content__3mSSQ ol, .Card_content__3mSSQ ul {
    list-style-position: inside;
}


@media screen and (max-width: 900px) {
    .Card_title__1R24K {
        font-size: 3vw;
    }

    .Card_content__3mSSQ {
        font-size: 1.7vw;
    }

    .Card_box__3dzPP {
        padding: 5vw;
    }
}

@media screen and (max-width: 500px) {
    .Card_title__1R24K {
        font-size: 6vw;
    }

    .Card_content__3mSSQ {
        font-size: 3vw;
        line-height: 15px;
    }

    .Card_box__3dzPP {
        padding: 4vw;
    }
}

/* @media screen and (max-width: 1000px) {
  .content {
    font-size: 5vw;
  }
} */

.Heading_box__1kKDW {
    box-shadow: 0 0 20px #000;
    background-image: url(/static/media/bg.8c784d25.webp);
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Heading_box__1kKDW p {
  font-size: min(6vw, 60px);
}

@media screen and (max-width: 700px) {
  .Heading_box__1kKDW p {
    font-size: 6vw;
  }
}

.Representatives_representatives__9R3uW {
    display: flex;
    flex-flow: column;
    grid-gap: 3vw;
    gap: 3vw;
    margin: 80px 50px;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .Representatives_representatives__9R3uW {
      grid-gap: 10vw;
      gap: 10vw;
    }
  }

.RepresentCard_cardOutline__1F9WF {
    box-sizing: border-box;
    border: 3px solid #000;
    box-shadow: grey 3px 3px;
    width: min(70vw, 1200px);
    height: min(20vw, 290px);
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    grid-gap: 1vw;
    gap: 1vw;
    background: #fff;
    align-items: center;
    border-radius: 20px;
    padding: 2% 5%;
}

.RepresentCard_personInfo__eChID {
    display: flex;
    flex-direction: column;
    grid-gap: 1vh;
    gap: 1vh;
    width: 100%;
}

.RepresentCard_name__2n4B9 {
    font-size: min(2.5vw, 40px);
    color: #000;
}

.RepresentCard_designation__3vFuA {
    font-size: min(2vw, 34px);
}

.RepresentCard_contact__33KGx {
    font-size: min(1.3vw, 22px);
}

.RepresentCard_button__3w_Qd {
    transition: background-color 0.5s ease-in-out;
    background-color: #000;
    outline: none;
    border: none;
    width: 120px;
    height: 2em;
    font-size: min(3.5vw, 20px);
}

.RepresentCard_button__3w_Qd:hover {
    transition: background-color 0.5s ease-in-out;
    background-color: #555;
}

.RepresentCard_image__p8ZeB {
    height: min(100%, 15vw);
}

.RepresentCard_cardOutline__1F9WF > a {
    margin-left: auto;
    margin-right: 5px;
}

@media screen and (max-width: 750px) {
    .RepresentCard_image__p8ZeB {
        width: 50%;
        height: auto;
    }

    .RepresentCard_cardOutline__1F9WF {
        width: 80vw;
        padding: 5vw;
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        grid-gap: 2vw;
        gap: 2vw;
    }

    .RepresentCard_name__2n4B9 {
        font-size: 6vw;
    }
    
    .RepresentCard_designation__3vFuA {
        font-size: 5vw;
    }
    
    .RepresentCard_contact__33KGx {
        font-size: 3.5vw;
    }

    .RepresentCard_cardOutline__1F9WF > a {
        margin: auto;
    }

    /* .cardOutline {
  height:
  } */
}

@media screen and (max-width: 640px) {
    .RepresentCard_cardOutline__1F9WF {
        flex-direction: column;
    }

    .RepresentCard_button__3w_Qd {
        width: 100%;
    }
}

/* @media screen and (max-width: 1000px) {
    .cardOutline {
        width: 50vw;
        padding: 5vh;
        flex-flow: column;
        height: 70vh;
        gap: 2vh;
    }

    .image {
        height: 50%;
    }

    .button {
        width: 40vw;
    }
} */

.Staff_representatives__vercy {
    display: flex;
    flex-flow: column;
    grid-gap: 3vw;
    gap: 3vw;
    margin: 80px 50px;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
}

@media screen and (max-width: 700px) {
    .Staff_representatives__vercy {
      grid-gap: 10vw;
      gap: 10vw;
    }
  }

.StaffCard_cardOutline__jzyHR {
    box-sizing: border-box;
    border: 3px solid #000;
    box-shadow: grey 3px 3px;
    width: min(70vw, 1200px);
    height: min(20vw, 290px);
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 1vw;
    gap: 1vw;
    background: #fff;
    align-items: center;
    border-radius: 20px;
    padding: 2% 5%;
}

.StaffCard_personInfo__CMoVA {
    display: flex;
    flex-direction: column;
    grid-gap: 1vh;
    gap: 1vh;
    width: 100%;
}

.StaffCard_name__3S4D7 {
    font-size: min(2.5vw, 40px);
    color: #000;
}

.StaffCard_designation__2Nlrp {
    font-size: min(2vw, 34px);
}

.StaffCard_contact__2VIKX {
    font-size: min(1.3vw, 22px);
}

.StaffCard_button__1TWTm {
    transition: background-color 0.5s ease-in-out;
    background-color: #000;
    outline: none;
    border: none;
    width: 120px;
    height: 2em;
    font-size: min(3.5vw, 20px);
}

.StaffCard_button__1TWTm:hover {
    transition: background-color 0.5s ease-in-out;
    background-color: #555;
}

.StaffCard_image__3OmTk {
    height: min(100%, 15vw);
}

.StaffCard_cardOutline__jzyHR > a {
    margin-left: auto;
    margin-right: 5px;
}

@media screen and (max-width: 750px) {
    .StaffCard_image__3OmTk {
        width: 50%;
        height: auto;
    }

    .StaffCard_cardOutline__jzyHR {
        width: 80vw;
        padding: 5vw;
        flex-direction: column;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        grid-gap: 2vw;
        gap: 2vw;
    }

    .StaffCard_name__3S4D7 {
        font-size: 6vw;
    }

    .StaffCard_designation__2Nlrp {
        font-size: 5vw;
    }

    .StaffCard_contact__2VIKX {
        font-size: 3.5vw;
    }

    .StaffCard_cardOutline__jzyHR > a {
        margin: auto;
    }

    /* .cardOutline {
  height:
  } */
}

@media screen and (max-width: 640px) {
    .StaffCard_cardOutline__jzyHR {
        flex-direction: column;
    }

    .StaffCard_button__1TWTm {
        width: 100%;
    }
}

/* @media screen and (max-width: 1000px) {
    .cardOutline {
        width: 50vw;
        padding: 5vh;
        flex-flow: column;
        height: 70vh;
        gap: 2vh;
    }

    .image {
        height: 50%;
    }

    .button {
        width: 40vw;
    }
} */

.Information_introInfo__16O-r {
    margin: 4vh;
}

.Information_card__2K6vI {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Information_box__3tDdR {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2vw;
    grid-column-gap: 5vw;
}

.Information_box__3tDdR > a {
  align-self: center;
}

@media screen and (max-width: 850px) {
    .Information_box__3tDdR {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .Information_box__3tDdR {
        display: flex;
        flex-direction: column;
        grid-gap: 6vw;
        gap: 6vw;
    }
}

.HostelCard_card__-JjDA {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    align-self: center;
    margin: auto;
}

.HostelCard_card__-JjDA:hover{
  border-color: aqua;
}

.HostelCard_column__216vR {
  align-self: center;
    margin: auto;
    padding: 4px;
}

.HostelCard_column__216vR > .HostelCard_card__-JjDA img {
    object-fit: contain;
}

.HostelCard_text__1mF8A {
  color: #000;
  text-align: center;
  padding: 5px 0;
  font-size: 1.5vw;
}

.HostelCard_details__VgNOE {
  text-align: center;
  color: #000;
  font-size: 1.2vw;
  padding: 5px 0;
  line-height: 20px;
}

.HostelCard_image__1OrEg {
  width: 20vw;
}


@media screen and (max-width: 850px) {
    .HostelCard_text__1mF8A {
      font-size: 3vw;
    }

    .HostelCard_details__VgNOE {
      font-size: 2.8vw;
    }

    .HostelCard_image__1OrEg {
      width: 40vw;
    }
}

@media screen and (max-width: 500px) {
  .HostelCard_column__216vR {
    width: 100%;
    display: block;
  }

  .HostelCard_card__-JjDA {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* margin-left: 40px;
    margin-top: 30px; */
  }

  .HostelCard_image__1OrEg {
    width: 90vw;
  }
}

.HostelPopUp_modal__1rXE1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
    cursor: default;
}

.HostelPopUp_link__2NfYS {
  color: #f00;
  text-align: center;
  font-size: 1.5vw;
  margin: auto;
}

.HostelPopUp_modal__1rXE1 .HostelPopUp_warden__3BCmX:hover {
  color: #6a6;
}

.HostelPopUp_card__25scV, .HostelPopUp_modal__1rXE1 .HostelPopUp_link__2NfYS {
    background-color: #fff;
    padding: 10px;
    /* width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center; */
}

.HostelPopUp_card__25scV {
    position: fixed;
    top: 50vh;
    left: 50vw;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 3;
    max-height: 80vh;
    overflow-y: scroll;
}

.HostelPopUp_link__2NfYS:hover {
    color: blue;
}

.HostelPopUp_image__1Vw6O {
  width: 40vw;
}

.HostelPopUp_frame__3g7UK {
    border: 0;
    width: 40vw;
    height: 40vh;
}

.HostelPopUp_text__2jzkw {
  color: #000;
  text-align: center;
  padding: 5px 0;
  font-size: 1.5vw;
}

.HostelPopUp_details__EWgJ0 {
  text-align: center;
  color: #000;
  font-size: 1.2vw;
  padding: 5px 0;
  line-height: 30px;
}

.HostelPopUp_linkWrap__3eGut{
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 700px) {
    .HostelPopUp_text__2jzkw {
      font-size: 3vw;
    }

    .HostelPopUp_details__EWgJ0 {
      font-size: 2.8vw;
    }

    .HostelPopUp_modal__1rXE1 .HostelPopUp_link__2NfYS {
      font-size: 3vw;
    }

    .HostelPopUp_image__1Vw6O {
      width: 80vw;
    }

    .HostelPopUp_frame__3g7UK {
        width: 80vw;
    }
}

.HomeCard_box__30Hw8 {
    background: #FFF;
    box-sizing: border-box;
    padding: 1.5vw;
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    width: 28vw;
    box-shadow: 2px 2px 5px 2px black;
}

.HomeCard_title__2RLQm {
    color: #000;
    margin: 2vw 0;
    font-size: min(4vw, 40px);
}

.HomeCard_content__1Jdfb {
    line-height: min(2vw, 25px);
    font-size: min(1.5vw, 20px);
    text-align: justify;
}

.HomeCard_content__1Jdfb a:hover {
    color: black;
}


@media screen and (max-width: 900px) {
    .HomeCard_title__2RLQm {
        font-size: 3vw;
    }

    .HomeCard_content__1Jdfb {
        font-size: 1.7vw;
    }

    .HomeCard_box__30Hw8 {
        width: 40vw;
        padding: 2vw;
    }
}

@media screen and (max-width: 500px) {
    .HomeCard_title__2RLQm {
        font-size: 6vw;
    }

    .HomeCard_content__1Jdfb {
        font-size: 3vw;
        line-height: 15px;
    }

    .HomeCard_box__30Hw8 {
        width: 80vw;
        padding: 4vw;
    }
}

/* @media screen and (max-width: 1000px) {
  .content {
    font-size: 5vw;
  }
} */

.Home_cardRow__2F0uI {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2vw 5vw 2vw 5vw;
    grid-gap: 2vw;
    gap: 2vw;
}

.Home_link__Xzd7l:hover {
    color: #000;
}

.Home_container__2poYm {
    width: 40vw;
}

.Home_frame__1Z-ww {
  width: 90vw;
}

.Home_ulTypeA__3o_3m {
    list-style-type: circle !important;
    list-style-position: inside;
}

@media screen and (max-width: 900px) {
    .Home_cardRow__2F0uI {
        grid-template-columns: 1fr 1fr;
        padding: 2vw 6vw;
        grid-gap: 3vw;
        gap: 3vw;
    }

    .Home_container__2poYm {
        width: 90vw;
    }
}


@media screen and (max-width: 500px) {
    .Home_cardRow__2F0uI {
        grid-template-columns: 1fr;
        grid-gap: 4vw;
        gap: 4vw;
    }
}

.HomeHeading_wrapper__1ahti {
    position: relative;
    background-image: url(/static/media/bg.8c784d25.webp);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.HomeHeading_inner__2BlmN {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeHeading_inner__2BlmN p {
    position: relative;
    font-size: min(6vw, 60px);
    text-align: center;
    color: #fff;
    width: 100%;
    padding: 0 0 1.5rem 0;
}

.HomeHeading_inner__2BlmN p::after {
    content: "";
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 700px) {
    .HomeHeading_box__2BL72 p {
        font-size: 6vw;
    }
}

.HomeHeading_slideContainer__2zk_U {
    overflow: hidden;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.HomeHeading_slideImg__1fp2_ {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.HomeHeading_arrow__3wAdL{
    position: absolute;
    bottom: 10%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

@media screen and (max-width: 300px) {
    .HomeHeading_arrow__3wAdL {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
}

.HomeHeading_arrowSpan__3o58D{
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #06A8FF;
    border-right: 5px solid #06A8FF;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: -10px;
    -webkit-animation: HomeHeading_animate__3p3RU 2s infinite;
            animation: HomeHeading_animate__3p3RU 2s infinite;
}
.HomeHeading_arrowSpan__3o58D:nth-child(2){
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
}
.HomeHeading_arrowSpan__3o58D:nth-child(3){
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
}
@-webkit-keyframes HomeHeading_animate__3p3RU {
    0%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(-20px,-20px);
                transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(20px,20px);
                transform: rotate(45deg) translate(20px,20px);
    }
}
@keyframes HomeHeading_animate__3p3RU {
    0%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(-20px,-20px);
                transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        -webkit-transform: rotate(45deg) translate(20px,20px);
                transform: rotate(45deg) translate(20px,20px);
    }
}
.Rules_para__2gFj2 {
    background: #fff;
    align-self: center;
    width: 95%;
    padding: 30px;
    border-radius: 10px;
    color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 30px;
    grid-gap: 30px;
    gap: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Rules_para__2gFj2 li, .Rules_para__2gFj2 p {
  font-size: 1vw;
  line-height: 2.5vw;
}

.Rules_para__2gFj2 p strong {
  font-size: 2vw;
}

@media screen and (max-width: 1000px) {
  .Rules_para__2gFj2 {
    padding: 50px 20px;
  }

  .Rules_para__2gFj2 li, .Rules_para__2gFj2 p {
    font-size: 3vw;
    line-height: 5vw;
  }

  .Rules_para__2gFj2 p strong {
    font-size: 5vw;
  }
}

@media screen and (max-width: 1300px) {
  .Rules_para__2gFj2 {
    padding: 50px 20px;
  }

  .Rules_para__2gFj2 li, .Rules_para__2gFj2 p {
    font-size: 2vw;
    line-height: 4vw;
  }

  .Rules_para__2gFj2 p strong {
    font-size: 4vw;
  }
}

.Team_representatives__3GhO- {
    display: flex;
    flex-flow: column;
    grid-gap: 3vw;
    gap: 3vw;
    margin: 80px 50px;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
}

.Team_end__aGdYI {
  font-size: min(2vw, 25px);
}

.Team_card__3e837 {
    background-color: white;
    width: min(60vw, 1000px);
    border: 1px solid black;
    box-shadow: black 1px 1px 4px 1px;
    font-size: min(3vw, 30px);
    padding: 30px;
    color: black;

    border-radius: 15px 15px;
}

.Team_card__3e837 > p {
    text-align: center;
    font-weight: 700;
}

.Team_list__qRfLZ {
    margin: 30px 10px;
    display: block;
}

.Team_list__qRfLZ > li {
    list-style-type: square !important;
    font-size: min(2vw, 20px);
    margin: 20px 10px;
}

.Team_list__qRfLZ > li > p:nth-child(1) {
    font-size: min(2.5vw, 25px);
    font-weight: 600;
}

@media screen and (max-width: 700px) {
    .Team_representatives__3GhO- {
      grid-gap: 10vw;
      gap: 10vw;
    }

    .Team_end__aGdYI {
      font-size: 5vw;
    }
  }

.root-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    align-items: center;
    grid-gap: 4vh;
    gap: 4vh;
}

body {
    background-color: #f2f2f2;
    color: #666;
}

a {
    text-decoration: none;
    color: #88d;
}

a:hover {
    color: white;
}

li {
    color: #000;
    list-style-type: circle;
}

.wrapper {
    padding: 6rem 0 4rem 0;
    position: relative;
    margin: 0;
}

.wrapper.style3 {
    background-color: #000;
    color: #bfbfbf;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    position: relative;
}

.wrapper.style3 input,
.wrapper.style3 select,
.wrapper.style3 textarea {
    color: #ffffff;
}

.wrapper.style3 a {
    color: #8a4680;
}

.wrapper.style3 strong,
.wrapper.style3 b {
    color: #ffffff;
}

.wrapper.style3 h1,
.wrapper.style3 h2,
.wrapper.style3 h3,
.wrapper.style3 h4,
.wrapper.style3 h5,
.wrapper.style3 h6 {
    color: #ffffff;
}

.wrapper.style3 blockquote {
    border-left-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 code {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 hr {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .box {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="submit"],
.wrapper.style3 input[type="reset"],
.wrapper.style3 input[type="button"],
.wrapper.style3 button,
.wrapper.style3 .button {
    background-color: #f2f2f2;
    color: #000 !important;
}

.wrapper.style3 input[type="submit"]:hover,
.wrapper.style3 input[type="reset"]:hover,
.wrapper.style3 input[type="button"]:hover,
.wrapper.style3 button:hover,
.wrapper.style3 .button:hover {
    background-color: white;
}

.wrapper.style3 input[type="submit"]:active,
.wrapper.style3 input[type="reset"]:active,
.wrapper.style3 input[type="button"]:active,
.wrapper.style3 button:active,
.wrapper.style3 .button:active {
    background-color: #e5e5e5;
}

.wrapper.style3 input[type="submit"].alt,
.wrapper.style3 input[type="reset"].alt,
.wrapper.style3 input[type="button"].alt,
.wrapper.style3 button.alt,
.wrapper.style3 .button.alt {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.25);
    color: #ffffff !important;
}

.wrapper.style3 input[type="submit"].alt:hover,
.wrapper.style3 input[type="reset"].alt:hover,
.wrapper.style3 input[type="button"].alt:hover,
.wrapper.style3 button.alt:hover,
.wrapper.style3 .button.alt:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 input[type="submit"].alt:active,
.wrapper.style3 input[type="reset"].alt:active,
.wrapper.style3 input[type="button"].alt:active,
.wrapper.style3 button.alt:active,
.wrapper.style3 .button.alt:active {
    background-color: rgba(255, 255, 255, 0.2);
}

.wrapper.style3 input[type="submit"].alt.icon:before,
.wrapper.style3 input[type="reset"].alt.icon:before,
.wrapper.style3 input[type="button"].alt.icon:before,
.wrapper.style3 button.alt.icon:before,
.wrapper.style3 .button.alt.icon:before {
    color: #999999;
}

.wrapper.style3 input[type="submit"].special,
.wrapper.style3 input[type="reset"].special,
.wrapper.style3 input[type="button"].special,
.wrapper.style3 button.special,
.wrapper.style3 .button.special {
    background-color: #8a4680;
    color: #ffffff !important;
}

.wrapper.style3 input[type="submit"].special:hover,
.wrapper.style3 input[type="reset"].special:hover,
.wrapper.style3 input[type="button"].special:hover,
.wrapper.style3 button.special:hover,
.wrapper.style3 .button.special:hover {
    background-color: #9b4f90;
}

.wrapper.style3 input[type="submit"].special:active,
.wrapper.style3 input[type="reset"].special:active,
.wrapper.style3 input[type="button"].special:active,
.wrapper.style3 button.special:active,
.wrapper.style3 .button.special:active {
    background-color: #793d70;
}

.wrapper.style3 label {
    color: #ffffff;
}

.wrapper.style3 input[type="text"],
.wrapper.style3 input[type="password"],
.wrapper.style3 input[type="email"],
.wrapper.style3 select,
.wrapper.style3 textarea {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="text"]:focus,
.wrapper.style3 input[type="password"]:focus,
.wrapper.style3 input[type="email"]:focus,
.wrapper.style3 select:focus,
.wrapper.style3 textarea:focus {
    border-color: #8a4680;
    box-shadow: 0 0 0 1px #8a4680;
}

.wrapper.style3 .select-wrapper:before {
    color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"] + label,
.wrapper.style3 input[type="radio"] + label {
    color: #bfbfbf;
}

.wrapper.style3 input[type="checkbox"] + label:before,
.wrapper.style3 input[type="radio"] + label:before {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 input[type="checkbox"]:checked + label:before,
.wrapper.style3 input[type="radio"]:checked + label:before {
    background-color: #8a4680;
    border-color: #8a4680;
    color: #ffffff;
}

.wrapper.style3 input[type="checkbox"]:focus + label:before,
.wrapper.style3 input[type="radio"]:focus + label:before {
    border-color: #8a4680;
    box-shadow: 0 0 0 1px #8a4680;
}

.wrapper.style3 ::-webkit-input-placeholder {
    color: #999999 !important;
}

.wrapper.style3 :-moz-placeholder {
    color: #999999 !important;
}

.wrapper.style3 ::-moz-placeholder {
    color: #999999 !important;
}

.wrapper.style3 :-ms-input-placeholder {
    color: #999999 !important;
}

.wrapper.style3 .formerize-placeholder {
    color: #999999 !important;
}

.wrapper.style3 li {
    border-top-color: rgba(255, 255, 255, 0.25);
    list-style-type: circle;
}

.wrapper.style3 header p {
    color: #999999;
}

.wrapper.style3 table tbody tr {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
}

.wrapper.style3 table th {
    color: #ffffff;
}

.wrapper.style3 table thead {
    border-bottom-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table tfoot {
    border-top-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 table.alt tbody tr td {
    border-color: rgba(255, 255, 255, 0.25);
}

.wrapper.style3 .inner {
    position: relative;
    z-index: 2;
}

.wrapper.style3 p {
    text-transform: uppercase;
    font-weight: 300;
    margin: 0 0 0.5rem 0;
    padding: 0 0 1rem 0;
    letter-spacing: 0.25rem;
}

.wrapper.style3 p:after {
    content: "";
    position: absolute;
    margin: auto;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
}

.wrapper.style3:before {
    transition: opacity 3s ease;
    transition-delay: 1.25s;
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.yvebot-form {
    display: none;
}

.yvebot-conversation {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    overflow-y: scroll;
    width: 400px;
    max-height: 400px;
    padding: 20px;
}

.yvebot-thread {
    width: 60%;
    padding: 12px;
    border-radius: 15px;
    line-height: 20px;
    box-shadow: black 1px 1px 5px;
}

.yvebot-thread-bot {
    align-self: flex-start;
    background-color: rgb(58, 135, 223);
    color: white;
}

.yvebot-thread-user {
    align-self: flex-end;
    background-color: white;
    color: black;
}

.yvebot-message-bubbles {
    width: 80%;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    margin: 6% auto;
}

.yvebot-thread.yvebot-thread-bot.yvebot-thread-typing {
    display: none;
}


.yvebot-message-bubbleBtn {
  -webkit-appearance: none;
          appearance: none;
  background-color: #3fc246;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.yvebot-message-bubbleBtn:hover {
  background-color: #2c974b;
}

@media screen and (max-width: 700px) {
  .yvebot-conversation {
    width: 90%;
  }
}

.ChatBot_chatbot__35WSs {
    border: 3px solid #000;
    background-color: #fff;
}

.ChatBot_bottomRight__1FpkP {
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 10;
}

.ChatBot_topBar__25TIq {
  border-bottom: 3px solid #000;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 2px 0;
}

.ChatBot_close__tCxMe {
  border: none;
  background-color: #fff;
}

.ChatBot_ask__2lME8 {
  display: flex;
  border: none;
  justify-content: flex-end;
  background-color: transparent;
  transition: 0.5s;
  margin: 10px;
  right: 0;
}

.ChatBot_ask__2lME8:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.ChatBot_ask__2lME8:active {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.ChatBot_invisible__333HA {
  display: none;
}

@media screen and (max-size: 700px) {
  .ChatBot_chatbot__35WSs {
    width: 1vw;
  }
}

.Gallery_gallery__3y12c {
  margin: 20px 0;
  width: 60%;
  height: auto;
}

@media screen and (max-width: 700px) {
  .Gallery_gallery__3y12c {
    width: 90%;
  }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

